import { googleVerify, login } from "@/axios";
import withAuth from "@/components/auth/withAuth";
import { setToken, setUser } from "@/storage";
import { useRouter } from "next/router";
import React, { useState } from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import LoadingButton from "@/components/shared/button/loadingButton/LoadingButton";
import { routesURL } from "@/components/urls";
import useTitle from "@/components/hooks/useTitle";
import useToast from "@/components/shared/snackBar/useToast";
import FlamingosUppercaseLogo from "@/components/assets/logo/FlamingosUppercaseLogo";
import posthog from "posthog-js";
import { useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
const GoogleLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useToast();
  const router = useRouter();

  const login = useGoogleLogin({
    onSuccess: async (credToken) => {
      const userInfo = await fetch(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${credToken.access_token}` },
          method: "GET",
        }
      );

      const { email } = await userInfo.json();
      // const { email, name, picture } = jwtDecode(credToken.access_token);

      googleVerify(email)
        .then((res) => {
          if (res.status === 200) {
            const {
              data: { token },
            } = res.data;
            setToken(token);
            setUser(email);
            enqueueSnackbar("Logging in", "success");
            setIsLoading(false);
            posthog.identify(email);
            const redirect = routesURL.dasboard;
            router.push(redirect);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          const { response } = err;
          const { status, data } = response || {};
          if (status) {
            enqueueSnackbar(data?.detail, "error");
          } else {
            enqueueSnackbar("Something went wrong", "error");
          }
        });
    },
    onError: (e) => {
      setIsLoading(false);
    },
  });
  return (
    <LoadingButton
      onClick={() => {
        setIsLoading(true);
        login();
      }}
      isLoading={isLoading}
      disabled={isLoading}
      loaderHeight={20}
      loaderWidth={20}
      classes=" w-full py-4 text-2xl mt-14 border rounded-lg  disabled:bg-neutral-500 relative"
    >
      <FcGoogle size={20} className=" absolute top-4 left-3" />
      Login with google
    </LoadingButton>
  );
};
const Login = () => {
  useTitle("Login");
  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { enqueueSnackbar } = useToast();
  const email = watch("email");
  const password = watch("password");
  const handleClick = async () => {
    if (!email.length || !password.length) {
      enqueueSnackbar("Please fill all the fields", "error");
      return;
    }

    setIsLoading(true);
    const formData = new URLSearchParams();
    formData.append("username", email);
    formData.append("password", password);
    try {
      const { data, status } = await login(formData);
      if (data.status === "successful" && status === 200) {
        const {
          data: { token, email, last_login },
        } = data;
        setToken(token);
        setUser(email);
        enqueueSnackbar("Logging in", "success");
        posthog.identify(email);
        const redirect = routesURL.dasboard;
        router.push(redirect);
      }
    } catch (error) {
      const { response } = error;
      const { status, data } = response || {};
      if (status) {
        enqueueSnackbar(data?.detail, "error");
      } else {
        enqueueSnackbar("Something went wrong", "error");
      }
      setIsLoading(false);
    }
  };

  return (
    <div className=" !min-h-[calc(100vh)] flex items-center justify-center max-sm:p-4 main-container bg-white">
      <motion.form
        initial={{ scale: 0.3, opacity: 0.4 }}
        animate={{ scale: 1, opacity: 1 }}
        onSubmit={handleSubmit(handleClick)}
        className=" bg-white min-h-[80vh] w-[450px] max-md:min-w-[350px] max-sm:min-w-[200px] rounded-3xl px-[30px] py-[40px]"
      >
        <div className=" flex items-center justify-center">
          <FlamingosUppercaseLogo height={"35px"} width={"auto"} />
        </div>
        <div className=" text-center font-light mt-16">
          <h2 className=" text-5xl font-semibold leading-tight text-black-1">
            welcome back
          </h2>
          <p className=" mt-8 text-3xl"></p>
        </div>
        <div className="mt-[30px]">
          <input
            type="email"
            placeholder="Enter your email"
            className="w-full p-4 outline-none border border-neutral-300 rounded-lg mt-4 text-2xl appearance-none"
            {...register("email")}
          />
          <input
            type="password"
            placeholder="Enter your password"
            className="w-full p-4 outline-none border border-neutral-300 rounded-lg mt-8 text-2xl appearance-none"
            {...register("password")}
          />
          <LoadingButton
            isLoading={isLoading}
            disabled={isLoading}
            loaderHeight={20}
            loaderWidth={20}
            type="submit"
            classes=" w-full py-4 text-2xl mt-14 text-white rounded-lg bg-slate-950 disabled:bg-neutral-500"
          >
            Login
          </LoadingButton>
        </div>
        <div className=" mt-7 text-center text-black-1">
          {"Don't have an account?"}{" "}
          <Link href={routesURL.signup}>
            <span className=" cursor-pointer underline">Sign up</span>
          </Link>
        </div>
        <div className=" relative w-full mt-10">
          <div className=" absolut w-full  border-t-[1.5px] top-1/2 left-0  -translate-y-1/2 " />
          <div className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white z-10 w-[30px] text-center">
            OR
          </div>
        </div>
        <div className=" mt-8">
          <GoogleLogin />
        </div>
      </motion.form>
    </div>
  );
};

export default Login;

export const getServerSideProps = withAuth((context) => {
  return {
    props: {},
  };
});
