import React, { useEffect, useState } from "react";
import cx from "classnames";
import { CircularProgress } from "@mui/material";
import { noop } from "@/components/utility";
const LoadingButton = ({
  disabled: Disabled = false,
  type = "button",
  classes = "",
  loaderHeight = 15,
  loaderWidth = 15,
  loaderColor = "white",
  loadingText = "",
  loadingTextClass = "",
  isLoading = false,
  persistLoading = false,
  children,
  showLoader = true,
  onClick = noop,
  hasOwnLoader = false,
  ownLoaderComponent: OwnLoaderComponent,
  loaderContainer = "",
  ...restBtn
}) => {
  const [loading, setLoading] = useState(isLoading);
  const [disabled, setDisabled] = useState(Disabled);
  useEffect(() => {
    if (persistLoading) {
      if (isLoading) setLoading(isLoading);
    } else {
      setLoading(isLoading);
    }
  }, [isLoading, persistLoading]);
  useEffect(() => {
    if (persistLoading) {
      if (Disabled) setDisabled(Disabled);
    } else {
      setDisabled(Disabled);
    }
  }, [persistLoading, Disabled]);
  return (
    <button
      disabled={disabled}
      type={type}
      className={cx(classes)}
      onClick={!loading ? onClick : noop}
      {...restBtn}
    >
      {loading ? (
        <div
          className={cx(
            " flex justify-center  items-center gap-4",
            loaderContainer
          )}
        >
          {showLoader && (
            <CircularProgress
              sx={{
                height: `${loaderHeight}px !important`,
                width: `${loaderWidth}px !important`,
                color: `${loaderColor} !important`,
              }}
            />
          )}
          {loadingText && (
            <span className={cx(loadingTextClass)}>{loadingText}</span>
          )}
          {hasOwnLoader && <OwnLoaderComponent />}
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default LoadingButton;
